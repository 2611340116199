<template>
  <div>
    <ModalPromovePeao @executaPromocao="executaPromocao" />
    <div style="text-align: center">
      <br /><br />
      <div id="board" :style="this.$store.getters.getBloquearTabuleiro ? 'pointer-events: none; width: 450px;' : 'width: 450px;'"></div>

      <!--Escurecedor do tabuleiro - Em caso de necessidade de escurece-lo apenas descomentar-->
      <div v-if="this.$store.getters.getBloquearTabuleiro" style="top: 49px; z-index:100000; position: absolute; width: 450px; height: 450px; background-color: transparent" />
      
      
      <!--Dados acerca do tabuleiro - Para quando precisar debugar-->
      <!-- 
      captured_pieces_w
      <div id="captured_pieces_w"></div>
      status
      <div id="status"></div>
      captured_pieces_b
      <div id="captured_pieces_b"></div>
      fen
      <div id="fen"></div>
      pgn
      <div id="pgn"></div>
      <p>jogada</p>
      {{contJogada}} -->
    </div>
  </div>
</template>

<script>
import ModalPromovePeao from "@/components/modais/ModalPromovePeao";
export default {
  data() {
    return {
      arrJogadasJogo: [],
      Status: null,
      Tentativas: null,
      theTabuleiro: null,
      moverPara: null,
      pecaPromocao: "q",
      theGame: null,
      theTarget: null,
      jogadas: [],
      contJogada: 0,
      contParImpar: 0,
      validaPeca: "",
      thePGNgame: [],
      createEllipsis: true,
      validaStatus: 0
    };
  },
  name: "newboard",
  components: { ModalPromovePeao },
  props: ["dadosExercicioAluno"],
  mounted() {
    this.$nextTick(() => {
      this.$store.commit("SET_SITUACAO_GAME_STOCK", null)
      this.$store.commit('SET_BLOQUEAR_TABULEIRO', false)
      this.verificaTentativas();
      console.log('1° get', this.Status)
      var that = this;
        that = this;
      var objJogada = {
        fen: null,
        peca: null,
        turnAluno: null,
      };
      function captured_pieces(fen) {
        var new_fen = fen.split(" ");
        var new_fen = new_fen[0];

        // Anzahl wei�e Bauern
        var W_P = new_fen.split("P").length - 1;
        // Geschlagen wei�e Bauern
        var G_W_P = 8 - W_P;
        //G_W_P = 8;
        var g_w_bauer = "";
        for (var a = 0; a <= G_W_P - 1; a++) {
          var verschiebung = a * 15;
          var figur =
            '<div  style="position:absolute; left:' +
            verschiebung +
            'px;"> <img src="/novo/img/chesspieces/wP.png" width="40" height="40"></div>';
          g_w_bauer = g_w_bauer + figur;
        }
        // Anzahl schwarze Bauern
        var b_p = new_fen.split("p").length - 1;
        // Geschlagen schwarze Bauern
        var g_b_p = 8 - b_p;
        //g_b_p = 8;
        var g_b_bauer = "";

        for (var a = 0; a <= g_b_p - 1; a++) {
          var verschiebung = a * 15;
          var figur =
            '<div  style="position:absolute; left:' +
            verschiebung +
            'px;"> <img src="/novo/img/chesspieces/bP.png" width="40" height="40"></div>';
          g_b_bauer = g_b_bauer + figur;
        }
        // Anzahl wei�e Springer
        var W_N = new_fen.split("N").length - 1;
        // Geschlagen wei�e Springer
        var G_W_N = 2 - W_N;
        //G_W_N = 2;
        var G_W_Springer = "";
        for (var a = 0; a <= G_W_N - 1; a++) {
          var verschiebung = 130 + a * 15;
          var figur =
            '<div  style="position:absolute; left:' +
            verschiebung +
            'px;"> <img src="/novo/img/chesspieces/wN.png" width="40" height="40"></div>';
          G_W_Springer = G_W_Springer + figur;
        }

        // Anzahl schwarze Springer
        var b_N = new_fen.split("n").length - 1;
        // Geschlagen schwarze Springer
        var G_b_N = 2 - b_N;
        //G_b_N = 2;
        var G_b_Springer = "";
        for (var a = 0; a <= G_b_N - 1; a++) {
          var verschiebung = 130 + a * 15;
          var figur =
            '<div  style="position:absolute; left:' +
            verschiebung +
            'px;"> <img src="/novo/img/chesspieces/bN.png" width="40" height="40"></div>';
          G_b_Springer = G_b_Springer + figur;
        }

        // Anzahl wei�e L�ufer
        var W_B = new_fen.split("B").length - 1;
        // Geschlagen wei�e L�ufer
        var G_W_B = 2 - W_B;
        //G_W_B = 2;
        var G_W_Laeufer = "";
        for (var a = 0; a <= G_W_B - 1; a++) {
          var verschiebung = 170 + a * 15;
          var figur =
            '<div  style="position:absolute; left:' +
            verschiebung +
            'px;"> <img src="/novo/img/chesspieces/wB.png" width="40" height="40"></div>';
          G_W_Laeufer = G_W_Laeufer + figur;
        }

        // Anzahl schwarze L�ufer
        var W_b = new_fen.split("b").length - 1;
        // Geschlagen schwarze L�ufer
        var G_W_b = 2 - W_b;
        //G_W_b = 2;
        var G_b_Laeufer = "";
        for (var a = 0; a <= G_W_b - 1; a++) {
          var verschiebung = 170 + a * 15;
          var figur =
            '<div  style="position:absolute; left:' +
            verschiebung +
            'px;"> <img src="/novo/img/chesspieces/bB.png" width="40" height="40"></div>';
          G_b_Laeufer = G_b_Laeufer + figur;
        }

        // Anzahl wei�e T�rme
        var W_R = new_fen.split("R").length - 1;
        // Geschlagen wei�e T�rme
        var G_W_R = 2 - W_R;
        //G_W_R = 2;
        var G_W_Tuerme = "";
        for (var a = 0; a <= G_W_R - 1; a++) {
          var verschiebung = 210 + a * 15;
          var figur =
            '<div  style="position:absolute; left:' +
            verschiebung +
            'px;"> <img src="/novo/img/chesspieces/wR.png" width="40" height="40"></div>';
          G_W_Tuerme = G_W_Tuerme + figur;
        }

        // Anzahl schwarze T�rme
        var b_R = new_fen.split("r").length - 1;
        // Geschlagen schwarze T�rme
        var G_b_R = 2 - b_R;
        //G_b_R = 2;
        var G_b_Tuerme = "";
        for (var a = 0; a <= G_b_R - 1; a++) {
          var verschiebung = 210 + a * 15;
          var figur =
            '<div  style="position:absolute; left:' +
            verschiebung +
            'px;"> <img src="/novo/img/chesspieces/bR.png" width="40" height="40"></div>';
          G_b_Tuerme = G_b_Tuerme + figur;
        }

        // Anzahl wei�e Dame
        var W_Q = new_fen.split("Q").length - 1;
        // Geschlagen wei�e Dame
        var G_W_Q = 1 - W_Q;
        //G_W_Q = 1;
        var G_W_Dame = "";
        for (var a = 0; a <= G_W_Q - 1; a++) {
          var verschiebung = 250 + a * 15;
          var figur =
            '<div  style="position:absolute; left:' +
            verschiebung +
            'px;"> <img src="/novo/img/chesspieces/wQ.png" width="40" height="40"></div>';
          G_W_Dame = G_W_Dame + figur;
        }

        // Anzahl schwarze Dame
        var W_q = new_fen.split("q").length - 1;
        // Geschlagen schwarze Dame
        var G_W_q = 1 - W_q;
        //G_b_q = 1;
        var G_b_Dame = "";
        for (var a = 0; a <= G_W_q - 1; a++) {
          var verschiebung = 250 + a * 15;
          var figur =
            '<div  style="position:absolute; left:' +
            verschiebung +
            'px;"> <img src="/novo/img/chesspieces/bQ.png" width="40" height="40"></div>';
          G_b_Dame = G_b_Dame + figur;
        }
        document.getElementById("captured_pieces_w").innerHTML =
          g_w_bauer + G_W_Springer + G_W_Laeufer + G_W_Tuerme + G_W_Dame;
        document.getElementById("captured_pieces_b").innerHTML =
          g_b_bauer + G_b_Springer + G_b_Laeufer + G_b_Tuerme + G_b_Dame;
      }
      var init = function() {
        var selfplay = false;
        //--- start example JS ---
        var board,
          game = new Chess(
            `${that.dadosExercicioAluno.fen} ${that.dadosExercicioAluno.cor[0]} KQkq - 0 1`
          ),
          statusEl = $("#status"),
          fenEl = $("#fen"),
          pgnEl = $("#pgn");

        // do not pick up pieces if the game is over
        // only pick up pieces for the side to move

        var onDragStart = function(source, piece, position, orientation) {
          board.position(game.fen());
          if (
            game.game_over() === true ||
            (game.turn() === "w" && piece.search(/^b/) !== -1) ||
            (game.turn() === "b" && piece.search(/^w/) !== -1)
          ) {
            return false;
          }
        };
        function makemove() {
          setRequest(game.fen());
          setTimeout(function() {
            ziehen(content);
            //   let pgn = null;

            //   for (let i = 0; i < 50; i++) {
            //     pgn = game.pgn().split("]")[2].replace(`${i}.`, " ").split(". ");
            //   }

            //   pgn.splice(0, 1);

            //   let PGNlength = pgn.length;
            //   objJogada.fen = board.fen();
            //   objJogada.peca = pgn[PGNlength - 1].split(" ")[1];
            //   objJogada.turnAluno = false;
          }, 3000);
        }

        function ziehen(zug) {
          // var zug_bot = zug.split("-");
          // var zug_von = zug_bot[0];
          // var zug_nach = zug_bot[1];
          // var zug_bot = `${zug[0]+zug[1]}`;
          var zug_von = `${zug[0]+zug[1]}`;
          var zug_nach = `${zug[2]+zug[3]}`

          var possibleMoves = game.moves();

          // game over
          if (possibleMoves.length === 0) return;
          game.move({
            from: zug_von,
            to: zug_nach,
            promotion: that.pecaPromocao, // NOTE: always promote to a queen for example simplicity
          });
          board.position(game.fen());
          updateStatus();
          document.getElementById("fen").innerHTML = game.fen();

          if (selfplay != false) {
            makemove();
          }
        }


        var onDrop = function(source, target, piece, newPos, oldPos) {
          that.theTarget = target;
          that.createEllipsis = false
          if (that.dadosExercicioAluno.cor[0] == "b" && that.createEllipsis) {
            objJogada = {
              turnAluno: false,
              turn: that.dadosExercicioAluno.cor[0],
              peca: "(...)",
              theBoard: board,
              fen: board.fen(),
            };
            that.$emit("criaBottaoJogada", objJogada);
          }
          if (
            (target == "h8" && piece.indexOf("P") > -1) && that.dadosExercicioAluno.cor == "white" ||
            (target == "g8" && piece.indexOf("P") > -1) && that.dadosExercicioAluno.cor == "white" ||
            (target == "f8" && piece.indexOf("P") > -1) && that.dadosExercicioAluno.cor == "white" ||
            (target == "e8" && piece.indexOf("P") > -1) && that.dadosExercicioAluno.cor == "white" ||
            (target == "d8" && piece.indexOf("P") > -1) && that.dadosExercicioAluno.cor == "white" ||
            (target == "c8" && piece.indexOf("P") > -1) && that.dadosExercicioAluno.cor == "white" ||
            (target == "b8" && piece.indexOf("P") > -1) && that.dadosExercicioAluno.cor == "white" ||
            (target == "a8" && piece.indexOf("P") > -1) && that.dadosExercicioAluno.cor == "white" ||
            (target == "h1" && piece.indexOf("P") > -1) && that.dadosExercicioAluno.cor == "black" ||
            (target == "g1" && piece.indexOf("P") > -1) && that.dadosExercicioAluno.cor == "black" ||
            (target == "f1" && piece.indexOf("P") > -1) && that.dadosExercicioAluno.cor == "black" ||
            (target == "e1" && piece.indexOf("P") > -1) && that.dadosExercicioAluno.cor == "black" ||
            (target == "d1" && piece.indexOf("P") > -1) && that.dadosExercicioAluno.cor == "black" ||
            (target == "c1" && piece.indexOf("P") > -1) && that.dadosExercicioAluno.cor == "black" ||
            (target == "p1" && piece.indexOf("P") > -1) && that.dadosExercicioAluno.cor == "black" ||
            (target == "a1" && piece.indexOf("P") > -1) && that.dadosExercicioAluno.cor == "black"
          ) {
            that.moverPara = {
              from: source,
              to: target,
            };
            if (game.in_check() === false) {
              that.showModal("modalNamePromovePeao");
              that.theGame = game;
              setTimeout(() => {
                segueJogadas();
                that.$store.commit('SET_BLOQUEAR_TABULEIRO', false)
              }, 6000);
            }
          } else {
            segueJogadas();
          }
          // see if the move is legal
          function segueJogadas() {
            that.$store.commit("SET_TYPE_EXERCICIO", 1);
            var move = game.move({
              from: source,
              to: target,
              promotion: that.pecaPromocao, // NOTE: always promote to a queen for example simplicity
            });

            // illegal move
            if (move === null) return "snapback";

            updateStatus();
            makemove();
            setTimeout(() => {
              that.atualizaDadosExercicio({fen:game.fen()})
            }, 500);

            if (!game.in_checkmate() && !game.in_draw()) {
              if (that.arrJogadasJogo.length == 0) {
                that.arrJogadasJogo.push(game.fen());
              } else if (
                game.fen() !=
                that.arrJogadasJogo[that.arrJogadasJogo.length - 1]
              ) {
                that.arrJogadasJogo.push(game.fen());
              }
              if (!game.in_checkmate() && !game.in_draw()) {
                setTimeout(() => {
                  if (!game.in_checkmate() && !game.in_draw()) {
                      that.arrJogadasJogo.push(game.fen());
                      that.salvaExercicio(that.arrJogadasJogo);
                      var objDiv = document.getElementById("thePlanilha");
                      objDiv.scrollTop = objDiv.scrollHeight;
                  }
                }, 6100);
              }
                that.$store.commit("SET_STOCK_THINK", true);
              that.contJogada += 1;
              that.contParImpar = 1;
            } else {
              let pgn = null;
              let tratedPGN = game.pgn().split("]");
              let a = game.pgn().split("]");
              let b = a[2].split(".");

              for (let i = 0; i < b.length; i++) {
                let c = b[i].split(" ");
                if (c[1] != undefined) {
                  objJogada = {
                    turnAluno: true,
                    turn: that.dadosExercicioAluno.cor[0],
                    peca: that.validaPeca == c[1] ? c[2] : c[1],
                    theBoard: board,
                  };
                }
              }
              that.thePGNgame.push(objJogada.peca);
              that.salvaExercicio(that.arrJogadasJogo);
            }

            let pgn = null;
            let tratedPGN = game.pgn().split("]");
            let a = game.pgn().split("]");
            let b = a[2].split(".");

            for (let i = 0; i < b.length; i++) {
              let c = b[i].split(" ");
              if (c[1] != undefined) {
                objJogada = {
                  turnAluno: true,
                  turn: that.dadosExercicioAluno.cor[0],
                  peca: that.validaPeca == c[1] ? c[2] : c[1],
                  theBoard: board,
                };
              }
            }

            setTimeout(() => {
              objJogada.fen = board.fen();
              that.thePGNgame.push(objJogada.peca);
              that.$emit("criaBottaoJogada", objJogada);
            }, 250);

            setTimeout(() => {
              let a = game.pgn().split("]");
              let b = a[2].split(".");


              for (let i = 0; i < b.length; i++) {
                let c = b[i].split(" ");
                if (c[1] != undefined) {
                  objJogada = {
                    fen: board.fen(),
                    turnAluno: false,
                    turn: that.dadosExercicioAluno.cor[0],
                    peca: c[2] ? c[2] : c[1],
                    theBoard: board,
                  };
                  that.validaPeca = objJogada.peca;
                }
              }

              that.$store.commit("SET_STOCK_THINK", false);
              that.contJogada += 1;
              that.contParImpar = 0;

              that.thePGNgame.push(objJogada.peca);
              if(!game.in_checkmate() && !game.in_draw()) that.$emit("criaBottaoJogada", objJogada);
            }, 3000);
          }
        };

        // update the board position after the piece snap
        // for castling, en passant, pawn promotion
        var onSnapEnd = function() {
          board.position(game.fen());
        };

        var updateStatus = function() {
          var status = "";

          var moveColor = "White";
          if (game.turn() === "b") {
            moveColor = "Black";
          }

          // checkmate?
          if (game.in_checkmate() === true) {  
            that.$store.commit("SET_SITUACAO_GAME_STOCK", 'vitoria');          
            setTimeout(() => {
              that.arrJogadasJogo.push(game.fen());
              that.salvaExercicio(that.arrJogadasJogo, 2);              
            }, 100);
            status = "Game over, " + moveColor + " is in checkmate.";
            if (game.turn() != that.dadosExercicioAluno.cor[0]) {
              that.Status = 2;              
              that.$store.commit("SET_NEXT_EXERCICIO", true);
              that.$store.commit("SET_STATUS_ALUNO_PGN", true);
              that.showModal("modalNameStatusExercicio");
              // that.verificaTentativas(true);
            } else {
              that.$store.commit("SET_SITUACAO_GAME_STOCK", 'derrota');   
              that.$store.commit("SET_STATUS_ALUNO_PGN", false);
              that.showModal("modalNameStatusExercicio");
              that.verificaTentativas(false);
              console.log('2° get', this.Status)
            }
          }

          // draw?
          else if (game.in_draw() === true) {
             that.$store.commit("SET_SITUACAO_GAME_STOCK", 'empate');
            // document.getElementById('animation-container').remove()
            setTimeout(() => {
              that.showModal("modalNameStatusExercicio");
              that.arrJogadasJogo.push(game.fen());
              //verificar isso
              // that.salvaExercicio(that.arrJogadasJogo, that.Tentativas > 3 ? 2 : 1);
              that.salvaExercicio(that.arrJogadasJogo, 1);

            }, 100);
            status = "Game over, drawn position";
            that.$store.commit("SET_STATUS_ALUNO_PGN", false);
            that.$store.commit("SET_STOCK_THINK", false);
          }

          // game still on
          else {
            status = moveColor + " to move";

            // check?
            if (game.in_check() === true) {
              status += ", " + moveColor + " is in check";
            }
          }
          // if(s != false){sound();}
          statusEl.html(status);
          fenEl.html(game.fen());
          pgnEl.html(game.pgn());

          //captured_pieces(game.fen());
        };

        var cfg = {
          draggable: true,
          position: `${that.dadosExercicioAluno.fen} ${that.dadosExercicioAluno.cor[0]} KQkq - 0 1`,
          onDragStart: onDragStart,
          onDrop: onDrop,
          orientation: that.dadosExercicioAluno.cor,
          onSnapEnd: onSnapEnd,
        };
        board = new ChessBoard("board", cfg);

        that.theTabuleiro = board;

        updateStatus();
        $("#flipOrientationBtn").on("click", board.flip);
        $("#whiteOrientationBtn").on("click", function() {
          board.orientation("white");
        });

        $("#blackOrientationBtn").on("click", function() {
          board.orientation("black");
        });

        $("#move").on("click", function() {
          setRequest(game.fen());
          setTimeout(function() {
            ziehen(content);
          }, 3000);
        });

        //--- end example JS ---
      }; // end init()
      $(document).ready(init);

      // function sound(){
      //     document.getElementById('sound').innerHTML = '<audio autoplay preload controls> <source src="sound/move.wav" type="audio/wav" /> </audio>';
      // }
      var s = "true";
      // function soundcheck() {
      //     s = document.getElementById("soundcheck").checked;
      // }
      function stockfcheck() {
        selfplay = document.getElementById("stockfcheck").checked;
        //if(selfplay != false){makemove();}
        //document.getElementById('test').innerHTML = selfplay;
      }
    });
  },
  methods: {
    atualizaDadosExercicio(objAtualizar) {
      // this.verificaTentativas();
      let obj = {
        exercicio_id: this.$route.params.idExercicio,
        user_id: this.$store.getters.getUserDatas.id,
        status: objAtualizar.status,
        tentativa: this.Tentativa,
        // tentativa: objAtualizar.tentativa,
        PGN: "vazio",
      };
      if (this.arrJogadasJogo.length > 0) {
        this.$store.commit('SET_BLOQUEAR_TABULEIRO', true)
        $.ajax({
          type: "PUT",
          url: `${this.VUE_APP_CMS}api/exercicioUser/${this.$route.params.temaGrupoExercicio}`,
          data: objAtualizar,
          dataType: "json",
          headers: {
            Authorization: this.$store.getters.getPerfilToken,
          },
          success: (response) => {
            // this.$toast.open({
            //   message: `Jogada salva com sucesso`,
            //   type: "success",
            //   duration: 2500,
            //   dismissible: true,
            //   position: "bottom-right"
            // });
          },
          error: (response) => {
            // this.$toast.open({
            //   message: `Algo de errado ocorreu e seu jogo não será salvo.`,
            //   type: "error",
            //   duration: 2500,
            //   dismissible: true,
            //   position: "bottom-right"
            // });
          },
        });
      }
    },
    verificaTentativas(statusSuccess = false) {
      if (this.$route.path != "/login") {
        let objAtualizar = {
          status: null,
          tentativa: null,
          fen: "",
        };
        $.ajax({
          type: "GET",
          url: `${this.VUE_APP_CMS}api/exercicioUser/${this.$route.params.temaGrupoExercicio}`,
          dataType: "json",
          headers: {
            Authorization: this.$store.getters.getPerfilToken,
          },
          success: (response) => {
            this.$store.commit("SET_TENTATIVAS_EXERCICIOS", response.tentativa);
            this.$store.commit("SET_EXERCICIO_CONCLUIDO", response.status);
            if (response.tentativa < 3) {
              objAtualizar.status = response.status !== null ? response.status : 1;
              this.Status = response.status !== null ? response.status : 1;              
              objAtualizar.tentativa = response.tentativa + 1;
              this.atualizaDadosExercicio(objAtualizar);
            } else if (response.tentativa >= 2 || statusSuccess) {
              objAtualizar.status = 2;
              objAtualizar.tentativa = response.tentativa + 1;
              this.atualizaDadosExercicio(objAtualizar);
              this.$emit("verificaTentativas", true);
            }
            this.Status = objAtualizar.status ? objAtualizar.status : 1;
            console.log('dentro da função', this.Status)
            this.Tentativas = response.tentativa == 0 ? 1 : Number(response.tentativa) + 1;
          },
          error: (response) => {
          },
        });
      }
    },
    salvarQuantidadeExerciciosPendentes(){
      this.$store.commit('SET_EXERCICIO_PENDENTE', Number(
        this.$store.getters.getDadosExercicioPaginacao
        .map(param => param.exercicioUser
        .filter(param => param.status == 2 && param.user_id == this.$store.getters.getUserDatas.id))
        .map(param => param.length)
        .filter(param => param === 0).length
      )
    )
    },
    salvaExercicio(val, val2 = null) {
      if (this.$route.path != "/login") {
        let objCriar = {
          pgn: this.thePGNgame,
          exercicio_id: this.$route.params.idExercicio,
          user_id: this.$store.getters.getUserDatas.id,
          status: this.validaStatus == 2 ? 2 : val2 ? val2 : this.Status,
          tentativa: this.Tentativas,
          fen: [],
        };
        this.validaStatus = objCriar.status
        val.forEach((el) => {
          objCriar.fen.push(el.split(" ")[0]);
        });

        let fenString = objCriar.fen.toString();
        objCriar.fen = fenString;

        if (objCriar.fen != "") {
          objCriar.pgn = objCriar.pgn.toString();
          $.ajax({
            type: "PUT",
            url: `${this.VUE_APP_CMS}api/exercicioUser/${this.$route.params.temaGrupoExercicio}`,
            data: objCriar,
            dataType: "json",
            headers: {
              Authorization: this.$store.getters.getPerfilToken,
            },
            success: (response) => {
              this.$store.commit('SET_BLOQUEAR_TABULEIRO', false)
              this.salvarQuantidadeExerciciosPendentes()

            },
            error: (response) => {
              this.$store.commit('SET_BLOQUEAR_TABULEIRO', false)
              this.salvarQuantidadeExerciciosPendentes()
              // this.toastGlobal(
              //   "error",
              //   "Algo de errado ocorreu e seu jogo não será salvo."
              // );
            },
          });
        }
      }
    },
    executaPromocao(val) {
      if (this.$route.path != "/login") {
        let tabuleiroPosicao = this.theTabuleiro.position();
        this.theTabuleiro.move(`${this.moverPara.from}-${this.moverPara.to}`);

        this.hideModal("modalNamePromovePeao");

        this.$store.commit('SET_BLOQUEAR_TABULEIRO', true)

        setTimeout(() => {
          if (tabuleiroPosicao.a7 && this.theTarget == "a8") {
            delete tabuleiroPosicao.a7;
            tabuleiroPosicao.a8 = `${
              this.dadosExercicioAluno.cor[0]
            }${val.toUpperCase()}`;
          }
          if (tabuleiroPosicao.b7 && this.theTarget == "b8") {
            delete tabuleiroPosicao.b7;
            tabuleiroPosicao.b8 = `${
              this.dadosExercicioAluno.cor[0]
            }${val.toUpperCase()}`;
          }
          if (tabuleiroPosicao.c7 && this.theTarget == "c8") {
            delete tabuleiroPosicao.c7;
            tabuleiroPosicao.c8 = `${
              this.dadosExercicioAluno.cor[0]
            }${val.toUpperCase()}`;
          }
          if (tabuleiroPosicao.d7 && this.theTarget == "d8") {
            delete tabuleiroPosicao.d7;
            tabuleiroPosicao.d8 = `${
              this.dadosExercicioAluno.cor[0]
            }${val.toUpperCase()}`;
          }
          if (tabuleiroPosicao.e7 && this.theTarget == "e8") {
            delete tabuleiroPosicao.e7;
            tabuleiroPosicao.e8 = `${
              this.dadosExercicioAluno.cor[0]
            }${val.toUpperCase()}`;
          }
          if (tabuleiroPosicao.f7 && this.theTarget == "f8") {
            delete tabuleiroPosicao.f7;
            tabuleiroPosicao.f8 = `${
              this.dadosExercicioAluno.cor[0]
            }${val.toUpperCase()}`;
          }
          if (tabuleiroPosicao.g7 && this.theTarget == "g8") {
            delete tabuleiroPosicao.g7;
            tabuleiroPosicao.g8 = `${
              this.dadosExercicioAluno.cor[0]
            }${val.toUpperCase()}`;
          }
          if (tabuleiroPosicao.h7 && this.theTarget == "h8") {
            delete tabuleiroPosicao.h7;
            tabuleiroPosicao.h8 = `${
              this.dadosExercicioAluno.cor[0]
            }${val.toUpperCase()}`;
          }
          if (tabuleiroPosicao.a2 && this.theTarget == "a1") {
            delete tabuleiroPosicao.a2;
            tabuleiroPosicao.a1 = `${
              this.dadosExercicioAluno.cor[0]
            }${val.toUpperCase()}`;
          }
          if (tabuleiroPosicao.b2 && this.theTarget == "b1") {
            delete tabuleiroPosicao.b2;
            tabuleiroPosicao.b1 = `${
              this.dadosExercicioAluno.cor[0]
            }${val.toUpperCase()}`;
          }
          if (tabuleiroPosicao.c2 && this.theTarget == "c1") {
            delete tabuleiroPosicao.c2;
            tabuleiroPosicao.c1 = `${
              this.dadosExercicioAluno.cor[0]
            }${val.toUpperCase()}`;
          }
          if (tabuleiroPosicao.d2 && this.theTarget == "d1") {
            delete tabuleiroPosicao.d2;
            tabuleiroPosicao.d1 = `${
              this.dadosExercicioAluno.cor[0]
            }${val.toUpperCase()}`;
          }
          if (tabuleiroPosicao.e2 && this.theTarget == "e1") {
            delete tabuleiroPosicao.e2;
            tabuleiroPosicao.e1 = `${
              this.dadosExercicioAluno.cor[0]
            }${val.toUpperCase()}`;
          }
          if (tabuleiroPosicao.f2 && this.theTarget == "f1") {
            delete tabuleiroPosicao.f2;
            tabuleiroPosicao.f1 = `${
              this.dadosExercicioAluno.cor[0]
            }${val.toUpperCase()}`;
          }
          if (tabuleiroPosicao.g2 && this.theTarget == "g1") {
            delete tabuleiroPosicao.g2;
            tabuleiroPosicao.g1 = `${
              this.dadosExercicioAluno.cor[0]
            }${val.toUpperCase()}`;
          }
          if (tabuleiroPosicao.h2 && this.theTarget == "h1") {
            delete tabuleiroPosicao.h2;
            tabuleiroPosicao.h1 = `${
              this.dadosExercicioAluno.cor[0]
            }${val.toUpperCase()}`;
          }
          this.pecaPromocao = `${val}`;
          this.theTabuleiro.position(tabuleiroPosicao);
          // this.theTabuleiro.position(this.theTabuleiro.)
          this.theTabuleiro.position(this.theTabuleiro.fen());
        }, 200);
      }
    },
  },
};
</script>

<style scoped></style>
