<template>
  <modal
    id="modalPromovePeao"
    name="modalNamePromovePeao"
    :height="'auto'"
    :width="'50%'"
    :draggable="true"
  >
   <div class="d-flex j-c-center" :style="`background-image: url('${bgModal}')`">
     <button @click="executaPromocao('q')" class="p-5 mx-3 my-4">
       <img :src="pecaAluno ? RainhaPreto : RainhaBranco" alt="promove Peão à Rainha">
     </button>
     <button @click="executaPromocao('r')" class="p-5 mx-3 my-4">
       <img :src="pecaAluno ? TorrePreto : TorreBranco" alt="promove Peão à Torre">
     </button>
     <button @click="executaPromocao('n')" class="p-5 mx-3 my-4">
       <img :src="pecaAluno ? CavaloPreto : CavaloBranco" alt="promove Peão à Cavalo">
     </button>
     <button @click="executaPromocao('b')" class="p-5 mx-3 my-4">
       <img :src="pecaAluno ? BispoPreto : BispoBranco" alt="promove Peão à Bispo">
     </button>
   </div>
  </modal>
</template>

<script>
export default {
  data(){
    return{
      RainhaBranco: require('../../assets/icones/iconeRainhaBranco.png'),
      RainhaPreto: require('../../assets/icones/iconeRainhaPreto.png'),
      TorreBranco: require('../../assets/icones/iconeTorreBranco.png'),
      CavaloBranco: require('../../assets/icones/iconeCavaloBranco.png'),
      BispoBranco: require('../../assets/icones/iconeBispoBranco.png'),
      RainhaPreto: require('../../assets/icones/iconeRainhaPreto.png'),
      TorrePreto: require('../../assets/icones/iconeTorrePreto.png'),
      CavaloPreto: require('../../assets/icones/iconeCavaloPreto.png'),
      BispoPreto: require('../../assets/icones/iconeBispoPreto.png'),
      bgModal: require('../../assets/bg/piaoRei.png'),
    }
  },
  props:["pecaAluno"],
  methods:{
    executaPromocao(peca){
      this.$emit('executaPromocao', peca)
      this.hideModal('modalNamePromovePeao')
    }
  }
}
</script>

<style scoped>
  button{
    border: 2px solid #000;
  }

  button:hover{
    background-color: rgba(0, 0, 0, .5);
  }

  button:hover img{
    transform: scale(1.5);
  }
</style>